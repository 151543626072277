// simple_form style form errors
export var remoteFormErrors = (function ($) {
  // Clear prior errors and render new ones
  //
  // @param [String] containerID  ID of the form or container that houses the error fields
  // @param [String] klass        Optional. ActiveRecord model class name if handling errors for a model form.
  // @param [Hash] errors         Errors object: { attribute: [message, message] }
  var errorFields = function (containerID, klass, errors) {
    var $form = $(containerID)

    // Clear errors
    errorMessage(containerID, null)

    // Flag new errors
    $.each(errors, (attr, message) => {
      var $field = klass != null ? $form.find('#' + klass + '_' + attr) : $form.find('#' + attr)
      var $controlGroup = $field.closest('div.control-group')
      $controlGroup.addClass('error')
      var messageTxt = message.join(', ').replace('.,', '.')
      $field.closest('div').append('<span class="error help-inline">' + messageTxt + '</span>')
    })
  }

  // Display error message for form
  //
  // @param [String] containerID  ID of the form or container that houses the error fields
  // @param [String] message      Error message
  var errorMessage = function (containerID, message) {
    var $form = $(containerID)

    // remove any prior error elements
    $form.find('.error').removeClass('error')
    $form.find('span.help-inline').remove()

    // show error header if provided
    if (message) {
      $form.find('.alert-error').text(message).removeClass('hidden')
    }
  }

  return {
    errorFields: errorFields,
    errorMessage: errorMessage
  }
})(jQuery)
