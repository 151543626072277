// Global utility functions for heavy front end pages
export var msUtil = (function($, window, document) {

  /*
   * Converts seconds to hours, used to translate Shift durations
   *
   * @param {Integer} seconds
   * @param {Boolean} suffix  Add 'hrs' to the result
   *
   * @return {String} "xx.xxhrs" if suffix
   * @return {Float} xx.xx if !suffix
   */
  var durationToHours = function(seconds, suffix) {
    var hours = I18n.toNumber((Math.round((seconds / 3600) *100) / 100), {precision: 2, strip_insignificant_zeros: true});
    if (suffix) { hours = I18n.t('ms_util.js.hours_duration', { num_hours: hours }) }
    return hours;
  };

  /*
   * Converts seconds to minutes, used to translate Shift durations
   *
   * @param {Integer} seconds
   * @param {Boolean} suffix  Add 'mins' to the result
   *
   * @return {String} "xxmins" if suffix
   * @return {String} "xxother_suffix" if suffix && other_suffix is a String
   * @return {Float} xx if !suffix
   */
  var durationToMinutes = function(seconds, suffix, other_suffix) {
    var minutes = Math.round((seconds / 60) *100) / 100;
    if (suffix && typeof other_suffix == "string" && other_suffix !== '') {
      minutes += other_suffix;
    } else if (suffix) {
      minutes += 'mins';
    }

    return minutes;
  };

  /**
   * Fancy formatting of date ranges
   * @param  {String} startDate Start of Date Range
   * @param  {String} endDate   End of Date Range
   * @return {String}           Fancy Date Range
   *
   * @example
   * Same Month: Mar 23 – 29, 2014
   *
   * @example
   *  Different Month, Same year: Mar 30 – Apr 05, 2014
   *
   * @example
   *  Different Year: Dec 29, 2013 – Jan 04, 2014
   */
  var formattedDateRange = function(startDate, endDate) {
    startDate = moment(startDate), endDate = moment(endDate);
    if (startDate.isSame(endDate, 'year')) {
      if (startDate.isSame(endDate, 'month')) {
        // Sep 23 – 23, 2015
        return startDate.format('MMM D') + ' - ' + endDate.format('D, YYYY');
      } else {
        // Sep 23 - Sep 23, 2014
        return startDate.format('MMM D') + ' - ' + endDate.format('MMM D, YYYY');
      }
    } else {
      // "Sep 23, 2014 – Sep 23, 2015"
      return startDate.format('MMM D, YYYY') + ' - ' + endDate.format('MMM D, YYYY');
    }
  };

  /**
   * Custom time handling:
   * @example Nov 13, 2014, 10:00
   *   Same day =>        "10:00"
   *   Yesterday =>       "Yesterday, 10:00"
   *   Same month/year => "Nov 13, 10:00"
   *   Different year =>  "Nov 13, 2014, 10:00"
   *
   * @param {Integer} timestamp
   * @return {String} Formatted time
   */
  var relativeTime = function(timestamp) {
    var currentTime = moment();
    var momentTimestamp = moment.unix(timestamp);

    if (currentTime.year() === momentTimestamp.year()) {
      if (currentTime.month() === momentTimestamp.month()) {
        switch (currentTime.date() - momentTimestamp.date()) {
        case 0: // same day
          formattedTimestamp = momentTimestamp.format('HH:mm');
          break;
        case 1: // yesterday
          formattedTimestamp = I18n.t('ms_util.js.yesterday_time', { time: momentTimestamp.format('HH:mm') });
          break
        default: // earlier day in same month/year
          formattedTimestamp = momentTimestamp.format('MMM D, HH:mm');
          break;
        }
      } else { // same year, different month
        formattedTimestamp = momentTimestamp.format('MMM D, HH:mm');
      }
    } else { // different year
      var formattedTimestamp = momentTimestamp.format('MMM D, YYYY, HH:mm');
    }
    return formattedTimestamp;
  };

  /**
   * CSS spinner for transition states
   *
   * @param {String} elementId  ID of element to overlay spinner over
   */
  var spinner = function(elementId) {
    var target = document.getElementById(elementId);
    if (!target) return null;

    target.style.position = 'relative'; // Spinner container must have relative positioning

    return new Spinner(
      {
        lines:      13,         // The number of lines to draw
        length:     20,         // The length of each line
        width:      10,         // The line thickness
        radius:     30,         // The radius of the inner circle
        corners:    1,          // Corner roundness (0..1)
        rotate:     0,          // The rotation offset
        direction:  1,          // 1: clockwise, -1: counterclockwise
        color:      document.config.msUtilColor,  // #rgb or #rrggbb or array of colors
        speed:      1,          // Rounds per second
        trail:      60,         // Afterglow percentage
        className:  'spinner'   // The CSS class to assign to the spinner
      }
    ).spin(target);
  };

  /*
   * Writes an Array of debug lines to console
   *
   * @example consoleDebug("Creating conversations", conversations);
   */
  var consoleDebug = function() {
    var lines = Array.prototype.slice.call(arguments);
    $.each(lines, function(i, line) {
      console.log(line);
    });
  };

  /**
   * Returns formatted result names that guide users to create new 'tags' in Select2 dropdowns
   * @param  {Object} item            Select2 Object
   * @param  {Object} query           Select2 Query
   * @param  {String} typeOfSelection Text of object getting created
   * @return {String}                 Formatted result
   */
  var formatResult = function(item, query, typeOfSelection) {
    var formattedResult = item.text.trim();
    if (formattedResult === query.term.trim()) {
      formattedResult = I18n.t('ms_util.js.select2_format', { type: typeOfSelection, query: query.term });
    }
    return formattedResult;
  };

  var naturalSort = function(a, b, options) {
    var re = /(^-?[0-9]+(\.?[0-9]*)[df]?e?[0-9]?$|^0x[0-9a-f]+$|[0-9]+)/gi,
      sre = /(^[ ]*|[ ]*$)/g,
      // eslint-disable-next-line no-useless-escape
      dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/,
      hre = /^0x[0-9a-f]+$/i,
      ore = /^0/
      options = options || {};
      var i = function(s) { return options.insensitive && (''+s).toLowerCase() || ''+s },
      // convert all to strings strip whitespace
      x = i(a).replace(sre, '') || '',
      y = i(b).replace(sre, '') || '',
      // chunk/tokenize
      xN = x.replace(re, '\0$1\0').replace(/\0$/,'').replace(/^\0/,'').split('\0'),
      yN = y.replace(re, '\0$1\0').replace(/\0$/,'').replace(/^\0/,'').split('\0'),
      // numeric, hex or date detection
      xD = parseInt(x.match(hre)) || (xN.length != 1 && x.match(dre) && Date.parse(x)),
      yD = parseInt(y.match(hre)) || xD && y.match(dre) && Date.parse(y) || null,
      oFxNcL, oFyNcL,
      mult = options.desc ? -1 : 1;
    // first try and sort Hex codes or Dates
    if (yD)
      if ( xD < yD ) return -1 * mult;
      else if ( xD > yD ) return 1 * mult;
    // natural sorting through split numeric strings and default strings
    for(var cLoc=0, numS=Math.max(xN.length, yN.length); cLoc < numS; cLoc++) {
      // find floats not starting with '0', string or 0 if not defined (Clint Priest)
      oFxNcL = !(xN[cLoc] || '').match(ore) && parseFloat(xN[cLoc]) || xN[cLoc] || 0;
      oFyNcL = !(yN[cLoc] || '').match(ore) && parseFloat(yN[cLoc]) || yN[cLoc] || 0;
      // handle numeric vs string comparison - number < string - (Kyle Adams)
      if (isNaN(oFxNcL) !== isNaN(oFyNcL)) { return (isNaN(oFxNcL)) ? 1 : -1; }
      // rely on string comparison if different types - i.e. '02' < 2 != '02' < '2'
      else if (typeof oFxNcL !== typeof oFyNcL) {
        oFxNcL += '';
        oFyNcL += '';
      }
      if (oFxNcL < oFyNcL) return -1 * mult;
      if (oFxNcL > oFyNcL) return 1 * mult;
    }
    return 0;
  };


  return {
    durationToHours:              durationToHours,
    durationToMinutes:            durationToMinutes,
    formattedDateRange:           formattedDateRange,
    relativeTime:                 relativeTime,
    spinner:                      spinner,
    consoleDebug:                 consoleDebug,
    naturalSort:                  naturalSort,
    select2: {
      formatResult:               formatResult
    }
  };

})(jQuery, window, document);
