import { user_prefers_12hr } from 'core/helpers/format_helpers.js';
import { TimesheetItem } from 'core/models/timesheet_item.js';
import { msUtil } from 'core/ms_util.js';
import { clearModalAlerts, renderModalAlert } from 'core/makeshift.js';
import { analytics } from 'core/analytics/analytics.js';

export var timesheets = (function($, window, document) {

  /*
  public
  */

  // Recipient for custom event triggers
  var timesheetEvents = $({});

  var init = function(settings) {
    var spinner = startSpinner();

    $.extend(true, config, settings, {
      selectors: {
        addButton:                $(config.elements.addButton),
        approveToggleButton:      $(config.elements.approveToggleButton),
        approveEntryButton:       $(config.elements.approveEntryButton),
        unapproveEntryButton:     $(config.elements.unapproveEntryButton),
        closeControls:            $('#close-controls'),
        closeTimesheetButton:     $(config.elements.closeTimesheetButton),
        departmentTimesheetButton:$(config.elements.departmentTimesheetButton),
        userTimesheetButton:      $(config.elements.userTimesheetButton),
        userTimesheetButtonName:  $(config.elements.userTimesheetButtonName),
        departmentTimesheetBox:   $('#department-timesheet-box'),
        overtimeHoursOnlyCheckbox:$('#overtime-hours-only-checkbox'),
        closedIcon:               $('i.fa-lock'),
        dataTableBody:            $('.department-timesheet-table tbody'),
        departmentTimesheetTable: $('.department-timesheet-table'),
        header:                   $('#department-timesheet-date-range'),
        employeeFilter:           $('#user-filter'),
        importPunchesButton:      $('#import-punches-button'),
        importPunchesFile:        $('#import-punches-file'),
        importPunchesForm:        $('#import-punches-form'),
        payPeriod:                $('#pay-period'),
        placeholderRow:           $('.department-timesheet-table tbody').find('tr.empty-row'),
        punchModal:               $('#punch-modal'),
        punchHistoryModal:        $('#punch-history-modal'),
        reopenControls:           $('#reopen-controls'),
        reopenTimesheetButton:    $(config.elements.reopenTimesheetButton),
        timesheetItemFilter:      $('#timesheet-item-filter'),
        timesheetRulesButton:     $('#timesheet-rules'),
        totalUserHours:           $('#department-timesheet-employee-hours'),
        userName:                 $('#department-timesheet-employee-name'),
        userTitle:                $('span.title')
      },
      jobSitesEnabled:            settings.jobSitesEnabled,
      paidBreaks:                 settings.paidBreaks,
      showBreakPunches:           settings.showBreakPunches,
      pusherKey:                  settings.pusherKey,
      privatePusherChannel:       settings.privatePusherChannel,
      pusherUserId:               settings.pusherUserId,
      photoPunchesEnabled:        settings.photoPunchesEnabled,
      trcEnabled:                 settings.trcEnabled,
      payCodeEnabled:             settings.payCodeEnabled,
      managerApproval:            settings.managerApproval,
      shiftBasedPunches:          settings.shiftBasedPunches,
      isReadOnlyTimesheets:       settings.isReadOnlyTimesheets,
      isEnterpriseCompany:        settings.isEnterpriseCompany,
      spinner:                    spinner
    });

    setupPusherAnalytics().then(() => {

      initPopState();
      initEmployeeFilter();
      initTimesheetItemFilter();
      initOvertimeHoursOnlyCheckbox();
      initTimesheetRulesButton();
      initActions();
      initTimesheetEvents();
      initPayPeriodNavigation();
      initTimesheetPusher();

      // Automatically load the timesheet for the first user
      config.selectors.userRows().first().trigger('click');
    });
  };

  const setupPusherAnalytics = () => {
    return new Promise((resolve, reject) => {
      config.privatePusherChannel.bind('pusher:subscription_succeeded', () => {
        analytics.subscribeToAnalyticsReportChannel(config.privatePusherChannel, 'overtime-hours', populateOvertimeHours, displayPusherError);
        resolve();
      });
    });
  };

  /*
  private
  */

  // Default settings
  var config = {
    data: {                                     // Persisted data and interface state for current pay period
      location:                   null,         // Location Information
      department:                 null,         // Department Information
      payPeriodStartDate:         null,         // Start date of pay period as YYYY-MM-DD
      payPeriodEndDate:           null,         // End date of pay period as YYYY-MM-DD
      selectedTimesheetItemId:    null,         // ID of TimesheetItem currently being edited
      selectedUserId:             null,         // ID of selected User
      selectedUserTimesheetItems: [],           // TimesheetItems for selected User (a subset of timesheetItems)
      timesheetId:                null,         // ID of closed Timesheet for pay period
      timesheetItems:             [],           // TimesheetItem objects for pay period
      timesheetItemsIdSequence:   0             // Generated unique TimesheetItem ID sequence
    },
    allowImport:                  false,        // Allow import of test Data on QA ENVs
    dateFmt:                      'YYYY-MM-DD', // Standard date format used by Ruby and moment
    departmentId:                 null,         // Current Department ID
    elements: {
      addButton:                  '#timesheet-add-entry',
      approveToggleButton:        '#approve-toggle-button',
      approveEntryButton:         '#timesheet-approve-entry',
      unapproveEntryButton:       '#timesheet-unapprove-entry',
      shiftBasedPunches:          '#shift_based_punches',
      shiftBasedPunchesAction:    '#shift_based_punches_action',
      closeTimesheetButton:       '#close-timesheet-link',
      departmentTimesheetButton:  '.download-department-timesheet',
      userTimesheetButton:        '.download-user-timesheet',
      userTimesheetButtonName:    '.download-user-timesheet-name',
      dataRows:                   'table.department-timesheet-table tbody tr:not(.empty-row)',
      editTimesheetButton:        '.edit-timesheet-btn',
      historyTimesheetButton:     '.history-timesheet-btn',
      reopenTimesheetButton:      '#reopen-timesheet-link',
      syncTimesheetButton:        '#sync-link',
      unlockPunchButton:          '#super-admin-unlock-punch',
      userRows:                   'div.user-row'
    },
    exceptionRules: {
      earlyPunchInAllowance:      0,            // Seconds by which an employee can punch in early without exception
      latePunchInAllowance:       0,            // Seconds by which an employee can punch in late without exception
      latePunchOutAllowance:      0             // Seconds by which an employee can punch out late without exception
    },
    filter: {
      exceptionTypes:             [],         // Only display Punch rows with exceptions
      overtimeHoursOnly:          false
    },
    permissions: {
      canReopenTimesheet: function(role) {
        return ['company_admin', 'location_admin'].indexOf(role) >= 0;
      }
    },
    routes: {
      departmentTimesheetsPath: function(payPeriodId) {
        var url = '/departments/' + config.departmentId + '/timesheets';
        if (payPeriodId) { url += '?pay_period_id=' + payPeriodId; }
        return url;
      },
      departmentTimesheetsXlsxPath: function(payPeriodId, userId) {
        var url = '/departments/' + config.departmentId + '/timesheets.xlsx';
        if (payPeriodId) { url += '?pay_period_id=' + payPeriodId; }
        if (userId) { url += '&user_id=' + userId; }
        return url;
      },
      newDepartmentPunchesPath:   function() { return '/departments/' + config.departmentId + '/punches/new'; },
      editPunchPath:              function(id) { return '/punches/' + id + '/edit'; },
      unlockPunchPath:            function(id) { return '/punches/' + id + '/unlock'; },
      shiftBasedPunchesPath:      function(id) { return '/departments/' + config.departmentId + '/punches/shift_based_punches'; },
      approvePunchPath:           function() { return '/departments/' + config.departmentId + '/punches/approve'; },
      unapprovePunchPath:         function() { return '/departments/' + config.departmentId + '/punches/unapprove'; },
      newDepartmentTimesheetPath: function() { return '/departments/' + config.departmentId + '/timesheets/new'; },
      destroyTimesheetPath:       function() { return '/timesheets/' + config.data.timesheetId; },
      auditsPunchPath:            function(id) { return '/punches/' + id + '/audits'; },
      shiftBasedPunchesModalPath: function(id) { return '/departments/' + config.departmentId + '/punches/shift_based_punches_modal'; }
    },
    selectors: {
      activeUserRow:              function() { return $(config.elements.userRows + ':not(.hidden).active').first(); },
      dataRows:                   function() { return $(config.elements.dataRows); },
      userRow:                    function(id) { return $(config.elements.userRows + '[data-user-id=' + id + ']'); },
      userRows:                   function() { return $(config.elements.userRows); },
      visibleUserRows:            function() { return $(config.elements.userRows + ':not(.hidden)'); }
    },
    ui: {
      highlightColor:             '#ffcc00',
      highlightInterval:          750,
      transitionInterval:         300
    }
  };

  // Handles ajax reloading of page contents from pay period navigation
  var initPopState = function() {
    History.Adapter.bind(window, 'statechange', () => {
      var state = History.getState();
      loadTimesheet(state.url);
    });
  };

  // Set up employment type, position, and name filtering on user rows
  var initEmployeeFilter = function() {
    config.selectors.employeeFilter.on('change', function() {
      var $rows = $('#department-timesheet-employees .user-row');
      var $el = $(this);
      var $keywords = $(this).val();

      if ($keywords !== null && $keywords.length > 0) {
        $rows.addClass('hidden').filter((idx, userRow) => {
          var validForFilters = userHas(userRow, 'emptype', getFilters('emptype', $el)) &&
                                userHas(userRow, 'user-id', getFilters('employee', $el)) &&
                                userHas(userRow, 'jobsite', getFilters('jobsite', $el)) &&
                                userHas(userRow, 'position', getFilters('position', $el)) &&
                                userHas(userRow, 'skill', getFilters('skill', $el));
          return validForFilters;
        }).removeClass('hidden');
      } else {
        $rows.removeClass('hidden');
      }

      // Select first User in list if filtering removed last active User
      if (!config.selectors.activeUserRow().length) {
        config.selectors.visibleUserRows().first().trigger('click');
      }
    });
  };

  var startSpinner = function() {
    const spinner = msUtil.spinner('department-timesheet-box');
    spinner.isRunning = true;
    return spinner;
  };

  var stopSpinner = function(spinner) {
    if (spinner && spinner.isRunning) {
      $('.spinner').fadeOut(300, () => {
        spinner.stop();
        spinner.isRunning = false;
      });
    }
  };

  // Retrieves data for pay period and generates visible elements
  var loadTimesheet = function(url, userId) {
    // Can only load the timesheet given a single user
    // Otherwise, reload the entire page
    if (userId === undefined) {
      location.reload();
      return;
    }

    const spinner = config.spinner;

    if (spinner && !spinner.isRunning) {
      config.spinner = startSpinner();
    }

    // Get all Shift and Punch data for the pay period
    // @important The data payload must be passed in order to bypass chrome bug that caches and displays json data on back button
    return $.ajax({
      url: url,
      data: { format: 'json', user_id: userId },
      dataType: 'json',
      cache: false
    })
      .then(data => {
      // Set pay period data
        config.selectors.payPeriod.select2('val', data.pay_period.id);
        config.data.timesheetId        = data.timesheet_id;
        config.data.payPeriodStartDate = data.pay_period.start_date;
        config.data.payPeriodEndDate   = data.pay_period.end_date;
        config.data.location           = data.location;
        config.data.department         = data.department;

        // Set header
        config.selectors.header.text(data.header);

        // Department Timesheet is editable if no closed Timesheet record exists
        toggleEditableControls();

        // Create TimesheetItems for all Users in pay period
        buildTimesheetItems(data);

        disableReopenButton(false);
      });
  };

  function updateCountText(count) {
    const $countSpan = $('#selected-exceptions-count');
    count > 0 ? $countSpan.text(`(${count})`).addClass('show') : $countSpan.removeClass('show').text('');
  }

  function updateExceptionsText(count) {
    const $exceptionsText = $('#exceptions-text');
    count > 0 ? $exceptionsText.removeClass('without-exceptions') : $exceptionsText.addClass('without-exceptions');
  }

  function updateFilterCount() {
    const count = $('.exception-checkbox:checked').length;
    updateCountText(count);
    updateExceptionsText(count);
  }

  const initOvertimeHoursOnlyCheckbox = () => {
    config.selectors.overtimeHoursOnlyCheckbox.on('change', function() {
      config.filter.overtimeHoursOnly = $(this).is(':checked');
      updateOvertimeHours();
    });
  };

  const updateOvertimeHours = () => {
    // Get fresh overtime hours report
    const spinner = config.spinner;

    if (spinner && !spinner.isRunning) {
      config.spinner = startSpinner();
    }

    triggerOvertimeCalculation(config.data.selectedUserId);
    renderUserRows(config.data.selectedUserId);
  };

  const initTimesheetItemFilter = () => {
    const $exceptionCheckboxes = $('.exception-checkbox');
    const $allExceptionsCheckbox = $('#all-exceptions-checkbox');

    // Unselect all checkboxes on page load
    $exceptionCheckboxes.add($allExceptionsCheckbox).prop('checked', false);

    $allExceptionsCheckbox.on('change', function() {
      // This implementation insures that the change event handler is only called once
      $exceptionCheckboxes.off('change');
      $exceptionCheckboxes.prop('checked', $(this).is(':checked'));
      $exceptionCheckboxes.on('change', exceptionCheckboxChangeHandler);

      updateFilterAndRender();
      updateFilterCount();
    });

    const exceptionCheckboxChangeHandler = () => {
      const allChecked = $exceptionCheckboxes.length === $('.exception-checkbox:checked').length;
      $allExceptionsCheckbox.prop('checked', allChecked);

      updateFilterAndRender();
      updateFilterCount();
    };

    $exceptionCheckboxes.on('change', exceptionCheckboxChangeHandler);

    const updateFilterAndRender = () => {

      const selectedExceptions = $exceptionCheckboxes.filter(':checked').map((_, checkbox) => $(checkbox).val()).get();
      config.filter.exceptionTypes = selectedExceptions.length > 0 ? selectedExceptions : [];

      updateOvertimeHours();

      $('#checkAll:checked').prop('checked', false);
    };

    updateFilterCount();
  };

  /**
   * Initializes the tooltip button that explains the Location Rules
   */
  var initTimesheetRulesButton = function() {
    config.selectors.timesheetRulesButton.qtip({
      prerender: false,
      content: {
        text: function() {
          return $(this).data('content');
        },
        title: function() {
          return $(this).data('original-title');
        },
        button: true
      },
      position: {
        my: 'right top',
        at: 'left top',
      },
      show: {
        event: 'click'
      },
      hide: {
        event: 'click'
      },
      style: {
        classes: 'qtip-bootstrap position-select qtip-timesheet-rules'
      }
    });
  };

  var updateTimesheetPunchAndAssociatedShift = function(selectedTimesheetItemId, data) {
    const timesheetItem = findTimesheetItem(selectedTimesheetItemId);

    // force an update to the punch's associated shift
    // if the position/job site/trc/pay code changed
    if (timesheetItem && timesheetItem.shift && data.punch.shift) {
      const updatedShift = { ...timesheetItem.shift };
      updatedShift.position_name = data.punch.shift.position_name;
      updatedShift.job_site_name = data.punch.shift.job_site_name;
      updatedShift.time_reporting_code = data.punch.shift.time_reporting_code;
      updatedShift.pay_code_formatted_name = data.punch.shift.pay_code_formatted_name;
      timesheetItem.update({ punch: data.punch, shift: updatedShift });
    } else {
      timesheetItem.update({ punch: data.punch });
    }
  };

  // Handles actions from timesheet interface and modals
  var initActions = function() {
    $('body').on('click', config.elements.userRows, function(e) {
      $('#checkAll').prop('checked',false);

      // Reset the overtime hours only checkbox when selecting user
      config.selectors.overtimeHoursOnlyCheckbox.prop('checked', false);
      config.filter.overtimeHoursOnly = false;

      renderUser(parseInt($(this).attr('data-user-id')));
    });
    $('body').on('change', '#checkAll', function(e) {
      $('.checkAll').prop('checked',$(this).prop('checked'));
      if ($('.checkAll:checked').length > 0) {
        $('#timesheet-approve-entry').closest('li').removeClass('disabled');
        $('#timesheet-unapprove-entry').closest('li').removeClass('disabled');
        $('#shift_based_punches').closest('li').removeClass('disabled');
      }else{
        $('#timesheet-approve-entry').closest('li').addClass('disabled');
        $('#timesheet-unapprove-entry').closest('li').addClass('disabled');
        $('#shift_based_punches').closest('li').addClass('disabled');
      }
    });
    $('body').on('click', '.checkAll', e => {
      if ($('.checkAll:checked').length === $('.checkAll').length) {
        $('#checkAll').prop('checked', true);
      } else {
        $('#checkAll').prop('checked', false);
      }
      if ($('.checkAll:checked').length > 0) {
        $('#timesheet-approve-entry').closest('li').removeClass('disabled');
        $('#timesheet-unapprove-entry').closest('li').removeClass('disabled');
        $('#shift_based_punches').closest('li').removeClass('disabled');
      }else{
        $('#timesheet-approve-entry').closest('li').addClass('disabled');
        $('#timesheet-unapprove-entry').closest('li').addClass('disabled');
        $('#shift_based_punches').closest('li').addClass('disabled');
      }
    })

      .on('cocoon:after-insert', () => {
        initPunchModalDatePickers();
      })

      .on('click', config.elements.addButton, e => {
        e.preventDefault();
        if (!isEditable()) { return false; }
        $('.checkAll:checked').prop('checked',false);
        $('#checkAll:checked').prop('checked',false);
        $('#timesheet-unapprove-entry').closest('li').addClass('disabled');
        $('#timesheet-approve-entry').closest('li').addClass('disabled');
        $('#shift_based_punches').closest('li').addClass('disabled');
        config.data.selectedTimesheetItemId = null;
        renderModal(config.routes.newDepartmentPunchesPath(), { user_id: config.data.selectedUserId });
      })

      .on('click', config.elements.shiftBasedPunches, e => {
        e.preventDefault();
        if (!isEditable()) { return false; }
        if ($('#shift_based_punches').closest('li').hasClass('disabled')) { return false; }
        if($('.checkAll:checked').length > 0){
          var startDate = config.data.payPeriodStartDate,
            endDate = config.data.payPeriodEndDate;
          var pay_period_range = msUtil.formattedDateRange(startDate, endDate);
          renderModal(config.routes.shiftBasedPunchesModalPath(), {pay_period_range: pay_period_range});
        }
      })

      .on('click', config.elements.shiftBasedPunchesAction, e => {
        e.preventDefault();
        if (!isEditable()) { return false; }
        if ($('#timesheet-approve-entry').closest('li').hasClass('disabled')) { return false; }
        if ($('#timesheet-unapprove-entry').closest('li').hasClass('disabled')) { return false; }
        if($('.checkAll:checked').length > 0){
          const spinner = startSpinner();

          var rowIds = [];
          var shiftsIds = [];
          var punchesIds = [];
          $('.checkAll:checked').each(function() {
            var entryId = $(this).val();
            var entryType = $(this).data('type');
            if (entryId !== '-' && entryType === 'shift') {
              shiftsIds.push(entryId);
            }else{
              punchesIds.push(entryId);
            }

            rowIds.push(config.data.selectedTimesheetItemId);
          });

          if(shiftsIds.length > 0 || punchesIds.length > 0){
            $.ajax({
              url: config.routes.shiftBasedPunchesPath(),
              type: 'POST',
              async: false,
              dataType: 'json',
              data: { shifts_ids: shiftsIds, punches_ids:punchesIds  },
              success: function(data) {
                if(data.fails.length > 0){
                  var messageHtml = '<p class="alert alert-error">'+ data.error_message +'</div>';
                  $('body #shift_based_errors').html(messageHtml);
                  $('#shift_based_punches_action').addClass('hidden');
                  $('#close_shift_based').removeClass('hidden');
                }else{
                  config.selectors.punchModal.modal('hide');
                }

                renderUser(config.data.selectedUserId);
              },
              error: function(xhr, status, error) {
                const error_message = xhr && xhr.responseJSON && xhr.responseJSON.error_message
                  ? xhr.responseJSON.error_message
                  : 'Something went wrong';
                var messageHtml = '<p class="alert alert-error">'+ error_message +'</div>';
                $('body #shift_based_errors').html(messageHtml);
                renderUser(config.data.selectedUserId);
              }
            });
          }

          stopSpinner(spinner);
          $('.checkAll:checked').prop('checked',false);
          $('#checkAll:checked').prop('checked',false);
          $('#timesheet-approve-entry').closest('li').addClass('disabled');
          $('#timesheet-unapprove-entry').closest('li').addClass('disabled');
          $('#shift_based_punches').closest('li').addClass('disabled');
        }

      })
      .on('click', config.elements.approveEntryButton, e => {
        e.preventDefault();
        if (!isEditable()) { return false; }
        if ($('#timesheet-approve-entry').closest('li').hasClass('disabled')) { return false; }
        if ($('#timesheet-unapprove-entry').closest('li').hasClass('disabled')) { return false; }
        if ($('#shift_based_punches').closest('li').hasClass('disabled')) { return false; }

        const punchesIds = [];

        if($('.checkAll:checked').length > 0){
          const spinner = config.spinner;

          if (spinner && !spinner.isRunning) {
            config.spinner = startSpinner();
          }

          $('.checkAll:checked').each(function() {
            var punchId = $(this).val();
            var entryType = $(this).data('type');
            if (punchId !== '-' && entryType === 'punch') {
              punchesIds.push(punchId);
            }
          });
          if(punchesIds.length > 0){
            $.ajax({
              url: config.routes.approvePunchPath(),
              type: 'POST',
              async: false,
              dataType: 'json',
              data: { punches_ids:punchesIds  },
              success: function(data) {
                renderUser(config.data.selectedUserId);
              },
              error: function(xhr, status, error) {
                const error_message = xhr && xhr.responseJSON && xhr.responseJSON.error_message
                  ? xhr.responseJSON.error_message
                  : 'Something went wrong';
                setErrorFlash(error_message);
                renderUser(config.data.selectedUserId);
              }
            });
          }else{
            stopSpinner(config.spinner);
          }
        }
        $('.checkAll:checked').prop('checked',false);
        $('#checkAll:checked').prop('checked',false);
        $('#timesheet-approve-entry').closest('li').addClass('disabled');
        $('#timesheet-unapprove-entry').closest('li').addClass('disabled');
        $('#shift_based_punches').closest('li').addClass('disabled');
      })

      .on('click', config.elements.unapproveEntryButton, e => {
        e.preventDefault();
        if (!isEditable()) { return false; }
        if ($('#timesheet-approve-entry').closest('li').hasClass('disabled')) { return false; }
        if ($('#timesheet-unapprove-entry').closest('li').hasClass('disabled')) { return false; }
        if ($('#shift_based_punches').closest('li').hasClass('disabled')) { return false; }

        const punchesIds = [];

        if($('.checkAll:checked').length > 0){
          const spinner = config.spinner;

          if (spinner && !spinner.isRunning) {
            config.spinner = startSpinner();
          }

          $('.checkAll:checked').each(function() {
            config.data.selectedTimesheetItemId = $(this).closest('tr').attr('data-timesheet-item-id');
            var punchId = $(this).val();
            var entryType = $(this).data('type');
            if (punchId !== '-' && entryType === 'punch') {
              punchesIds.push(punchId);
            }
          });
          if(punchesIds.length > 0){
            $.ajax({
              url: config.routes.unapprovePunchPath(),
              type: 'POST',
              async: false,
              dataType: 'json',
              data: { punches_ids:punchesIds  },
              success: function(data) {
                renderUser(config.data.selectedUserId);
              },
              error: function(xhr, status, error) {
                const error_message = xhr && xhr.responseJSON && xhr.responseJSON.error_message
                  ? xhr.responseJSON.error_message
                  : 'Something went wrong';
                setErrorFlash(error_message);
                renderUser(config.data.selectedUserId);
              }
            });
          }else{
            stopSpinner(config.spinner);
          }
        }


        // stopSpinner(config.spinner);
        $('.checkAll:checked').prop('checked',false);
        $('#checkAll:checked').prop('checked',false);
        $('#timesheet-approve-entry').closest('li').addClass('disabled');
        $('#timesheet-unapprove-entry').closest('li').addClass('disabled');
        $('#shift_based_punches').closest('li').addClass('disabled');
      })

      .on('click', config.elements.editTimesheetButton, function(e) {
        e.preventDefault();
        if (!isEditable()) { return false; }

        $('.checkAll:checked').prop('checked',false);
        $('#checkAll:checked').prop('checked',false);
        $('#timesheet-unapprove-entry').closest('li').addClass('disabled');
        $('#timesheet-approve-entry').closest('li').addClass('disabled');
        $('#shift_based_punches').closest('li').addClass('disabled');

        config.data.selectedTimesheetItemId = $(this).closest('tr').attr('data-timesheet-item-id');
        var timesheetItem = findTimesheetItem(config.data.selectedTimesheetItemId);
        if (timesheetItem.punch) {
          renderModal(config.routes.editPunchPath(timesheetItem.punch.id));
        } else {
          renderModal(config.routes.newDepartmentPunchesPath(), { user_id: config.data.selectedUserId, shift_id: timesheetItem.shift.id });
        }
      })

      .on('click', config.elements.historyTimesheetButton, function(e) {
        e.preventDefault();
        config.data.selectedTimesheetItemId = $(this).closest('tr').attr('data-timesheet-item-id');
        var timesheetItem = findTimesheetItem(config.data.selectedTimesheetItemId);
        renderHistoryModal(timesheetItem.punch.id);
      })

      .on('click', config.elements.closeTimesheetButton, e => {
        e.preventDefault();
        if (!isEditable()) { return false; }
        if (isCloseable()) {
          renderModal(config.routes.newDepartmentTimesheetPath(), { pay_period_id: config.selectors.payPeriod.select2('val') });
        } else {
          bootbox.alert(I18n.t('timesheets.index.modals.errors.cannot_close'));
        }
      })

      .on('click', config.elements.departmentTimesheetButton, e => {
        e.preventDefault();
        var pay_period_id = config.selectors.payPeriod.select2('val');
        window.location = config.routes.departmentTimesheetsXlsxPath( pay_period_id);
      })

      .on('click', config.elements.userTimesheetButton, e => {
        e.preventDefault();
        var pay_period_id = config.selectors.payPeriod.select2('val');
        window.location = config.routes.departmentTimesheetsXlsxPath( pay_period_id, config.data.selectedUserId);
      })

      .on('click', config.elements.unlockPunchButton, function(e) {
        e.preventDefault();

        // Get the punch id from the button
        const punchId = this.dataset.punchId;

        // Hide the edit modal
        config.selectors.punchModal.modal('hide');

        // Confirm the unlock
        bootbox.confirm(
          this.dataset.confirmationMessage,
          I18n.t('buttons.cancel'),
          I18n.t('buttons.ok'),
          confirmed => {
            if (confirmed) {
              const spinner = startSpinner();
              $.ajax({
                url: config.routes.unlockPunchPath(punchId),
                type: 'POST',
                success: function(data) {
                  findTimesheetItem(config.data.selectedTimesheetItemId).update({ punch: data.punch });
                },
                error: function(xhr, status, error) {
                  const error_message = xhr && xhr.responseJSON && xhr.responseJSON.error_message
                    ? xhr.responseJSON.error_message
                    : 'Something went wrong';
                  setErrorFlash(error_message);
                },
                complete: function() {
                  stopSpinner(spinner);
                }
              });
            }
          }
        );
      })

      .on('click', config.elements.reopenTimesheetButton, e => {
        e.preventDefault();

        var startDate = config.data.payPeriodStartDate,
          endDate = config.data.payPeriodEndDate;

        if (!config.permissions.canReopenTimesheet(config.userRole) || isEditable()) { return false; }

        var confirmationMessage = I18n.t('timesheets.index.modals.reopen.body', {
          pay_period_range: msUtil.formattedDateRange(startDate, endDate),
          location_name: config.data.location.name
        });
        confirmationMessage += '<br/><br/>';
        if (dateBetween(new Date(),startDate, endDate)) {
          confirmationMessage += I18n.t('timesheets.index.modals.reopen.body_current_period');
          confirmationMessage += '<br/><br/>';
        }
        confirmationMessage += I18n.t('timesheets.index.modals.reopen.confirmation');

        bootbox.confirm(
          confirmationMessage,
          I18n.t('buttons.cancel'),
          I18n.t('buttons.ok'),
          confirmed => {
            if (confirmed) {
              startSpinner();
              disableReopenButton(true);
              $.ajax({ url: config.routes.destroyTimesheetPath(), type: 'DELETE', dataType: 'json' });
            }
          });
      })

      .on('ajax:success', 'form.new_punch', (e, data) => {
        if (config.data.selectedTimesheetItemId) {
          updateTimesheetPunchAndAssociatedShift(config.data.selectedTimesheetItemId, data);
        } else {
          createTimesheetItem(data.punch);
        }
      })

      .on('ajax:success', 'form.edit_punch', (e, data) => {
        updateTimesheetPunchAndAssociatedShift(config.data.selectedTimesheetItemId, data);
      })

      .on('ajax:success', '[data-punch-id][data-method="delete"]', e => {
        e.stopPropagation(); // prevent containing form from firing ajax:success
        findTimesheetItem(config.data.selectedTimesheetItemId).update({ punch: null });
      })

      .on('ajax:success', 'form.new_timesheet', (e, data) => {
        loadTimesheet(location.href);
        config.selectors.punchModal.modal('hide');
      })

      .on('ajax:error', 'form.new_punch, form.edit_punch, form.new_timesheet', function(e, xhr, status, error) {
        clearModalAlerts();
        renderModalAlert(xhr.responseJSON);
        if ($(this).prop('id') === 'new_timesheet' && xhr.status === 400) {
          loadTimesheet(location.href);
        }
      });

    $('body').on('click', '.dropdown-content', e => {
      e.stopPropagation();
    });

    $('body').on('click', '#clear-filters', e => {
      e.stopPropagation();
      $('.dropdown-content input[type="checkbox"]').prop('checked', false);
      config.filter.exceptionTypes = [];
      renderUser(config.data.selectedUserId);
      updateFilterCount();
    });

    $('body').on('click', '.checkbox-item', function(e) {
      if (!$(e.target).is('input[type="checkbox"]') && !$(e.target).is('label')) {
        const checkbox = $(this).find('input[type="checkbox"]');
        checkbox.trigger('click');
      }
    });

    $('.dropdown-button').on('click', function() {
      const dropdownContent = $(this).next('.dropdown-content');
      if (dropdownContent.hasClass('show')) {
        dropdownContent.removeClass('show');
        setTimeout(() => {
          dropdownContent.css('display', 'none');
        }, 300);
      } else {
        dropdownContent.addClass('show');
        dropdownContent.css('display', 'block');
      }
      $(this).toggleClass('is-active');
    });

    $(document).on('click', e => {
      if (!$(e.target).closest('.dropdown').length) {
        const dropdowns = $('.dropdown-content');
        dropdowns.removeClass('show');
        $('.dropdown-button').removeClass('is-active');
        setTimeout(() => {
          dropdowns.css('display', 'none');
        }, 300);
      }
    });
  };

  /*
   * Handles events triggered by TimesheetItem objects
   *
   */
  var initTimesheetEvents = function() {
    timesheetEvents
      .on('timesheetItem:update', (e, timesheetItem) => {
        // Hide modal
        config.selectors.punchModal.modal('hide');

        // Update dom
        timesheetItem.selector().replaceWith(timesheetItem.render());
        var $tr = timesheetItem.selector();
        $tr.find('td').effect('highlight', {color: config.ui.highlightColor}, config.ui.highlightInterval);

        // Bind exception helpers
        $tr.find('[data-toggle=tooltip]').tooltip();

        // Update totals
        renderUserTotals();

        // Reset user exception flag for missing values
        flagUserException(config.data.selectedUserId);

        triggerOvertimeCalculation(config.data.selectedUserId);
      })
      .on('timesheetItem:destroy', (e, timesheetItem) => {
        // Hide modal
        config.selectors.punchModal.modal('hide');

        // Update dom
        timesheetItem.selector().remove();

        // Update totals
        renderUserTotals();

        // Reset user exception flag
        flagUserException(config.data.selectedUserId);

        // Remove timesheetItem from collection
        for (var i=0; i < config.data.timesheetItems.length; i++) {
          if (config.data.timesheetItems[i].id === timesheetItem.id) {
            config.data.timesheetItems.splice(i,1);
            break;
          }
        }

        triggerOvertimeCalculation(config.data.selectedUserId);
      });
  };

  /*
   * Handles page navigation between pay periods
   *
   */
  var initPayPeriodNavigation = function() {
    config.selectors.payPeriod.on('change', function(e) {
      var url = config.routes.departmentTimesheetsPath($(this).val());
      // Note: we used to trigger PushState here which would trigger a bunch of AJAX
      // to reload the timesheet. However, it was buggy. So we're hard-refreshing now
      // until we can rebuild this in Vue or something.
      window.location = url;
    });
  };

  /**
   * Initializes Pusher for Timesheet reopening
   */
  var initTimesheetPusher = function() {
    var pusher = window.getPusherInstance(config.pusherKey);

    var timesheetChannelName = 'timesheet-destroy-' + config.departmentId;
    var timesheetPusherChannel = pusher.subscribe(timesheetChannelName);

    // Pusher event triggered by successful timesheet destroy
    timesheetPusherChannel.bind('timesheet-destroy-complete', data => {
      loadTimesheet(location.href);
    });

    // Pusher event triggered by failed timesheet destroy
    timesheetPusherChannel.bind('timesheet-destroy-failed', data => {
      stopSpinner();
      disableReopenButton(false);

      setErrorFlash(data.message);
    });
  };

  const populateOvertimeHours = analyticsReport => {
    const otHoursData = analyticsReport.data;

    const updateTimesheetItemRow = (timesheetItem, overtimeSeconds) => {
      timesheetItem.overtimeSeconds = overtimeSeconds;

      // Need to redo vallidation on the timesheetItem (Check for any exceptions)
      timesheetItem.validate();

      // Need to redo filtering if overtime filter is selected
      if (config.filter.overtimeHoursOnly) {
        config.selectors.dataRows().remove();

        config.data.selectedUserTimesheetItems = filterAndSortTimesheetItems(config.data.timesheetItems, config.data.selectedUserId);

        $.each(config.data.selectedUserTimesheetItems, (i, timesheetItem) => {
          config.selectors.placeholderRow.before(timesheetItem.render());
        });

        renderUserTotals();
      }

      const updatedRow = timesheetItem.render();
      timesheetItem.selector().replaceWith(updatedRow);

      // Bind exception helpers
      $('[data-toggle=tooltip]').tooltip();
    };

    config.data.timesheetItems.forEach(timesheetItem => {
      // Find the corresponding overtime detail
      const otDetail = otHoursData.overtime_details.find(detail => timesheetItem.punch && timesheetItem.punch.id === detail.id);

      if (otDetail) {
        updateTimesheetItemRow(timesheetItem, otDetail.overtime_seconds);
      } else if (timesheetItem.overtimeSeconds) {
        // Check if overtimeSeconds is already set and re-render if necessary
        updateTimesheetItemRow(timesheetItem, null);
      }
    });

    stopSpinner(config.spinner);
  };

  const displayPusherError = () => {
    setErrorFlash('Unable to load Overtime Hours');
  };

  var setErrorFlash = function(message) {
    var messageHtml = '<div class="alert alert-error"><a class="close" data-dismiss="alert">×</a><div id="flash_error">'+ message +'</div></div>';
    $('body #flash-messages').html(messageHtml);
  };

  /**
   * Toggles the Close/Reopen controls based on whether Timesheet has been closed
   */
  var toggleEditableControls = function() {
    if (isEditable()) {
      config.selectors.departmentTimesheetTable.removeClass('table-readonly');
      config.selectors.closedIcon.addClass('hidden');
      config.selectors.closeControls.removeClass('hidden');
      config.selectors.reopenControls.addClass('hidden');
      config.selectors.addButton.closest('li').removeClass('hidden');
      config.selectors.approveToggleButton.closest('li').removeClass('hidden');
      config.selectors.approveEntryButton.closest('li').removeClass('hidden');
      config.selectors.unapproveEntryButton.closest('li').removeClass('hidden');
      config.selectors.timesheetItemFilter.closest('li').removeClass('hidden');
      config.selectors.overtimeHoursOnlyCheckbox.closest('li').removeClass('hidden');
    } else {
      config.selectors.departmentTimesheetTable.addClass('table-readonly');
      config.selectors.closedIcon.removeClass('hidden');
      config.selectors.closeControls.addClass('hidden');
      config.selectors.addButton.closest('li').addClass('hidden');
      config.selectors.approveToggleButton.closest('li').addClass('hidden');
      config.selectors.approveEntryButton.closest('li').addClass('hidden');
      config.selectors.unapproveEntryButton.closest('li').addClass('hidden');
      config.selectors.timesheetItemFilter.closest('li').addClass('hidden');
      config.selectors.overtimeHoursOnlyCheckbox.closest('li').addClass('hidden');
      if (config.permissions.canReopenTimesheet(config.userRole)) {
        config.selectors.reopenControls.removeClass('hidden');
      }
    }
  };

  /*
   * Creates TimesheetItem objects from JSON for all Users/dates
   *
   * @param {Object} payPeriodData Raw JSON for pay period
   *
   */
  var buildTimesheetItems = function(payPeriodData) {
    config.data.timesheetItems = []; // reset prior load
    var timsheetIsEditable = isEditable();

    $.each(payPeriodData.user_ids, (index, userId) => {
      var punchedShiftIds = []; // running tally of User's Shifts that have been associated with Punches
      var startDate = moment(payPeriodData.pay_period.start_date);
      var endDate   = moment(payPeriodData.pay_period.end_date);
      var exceptionOptions = {
        hasOpenPunches: false,
        hasExceptions: false
      };

      // User data for pay period
      for (var date = startDate; !date.isAfter(endDate); date.add(1, 'days')) {
        var formattedDate = date.format('YYYY-MM-DD');

        // Create TimesheetItems for all Punches (with optional Shift) for the date
        var punches = _.where(payPeriodData.punches, { user_id: userId, punched_in_date: formattedDate });

        $.each(punches, (i, punch) => {
          var shift = null;
          if (punch.shift_id) {
            punchedShiftIds.push(punch.shift_id);
            shift = _.findWhere(payPeriodData.shifts, { id: punch.shift_id });
          }
          var timesheetItem = new TimesheetItem(newTimesheetItemId(), userId, punch, shift, config.exceptionRules, timsheetIsEditable, config.jobSitesEnabled, config.showBreakPunches, config.paidBreaks, config.photoPunchesEnabled, config.trcEnabled, config.managerApproval, config.shiftBasedPunches, config.payCodeEnabled, config.isReadOnlyTimesheets, config.isEnterpriseCompany);
          if (timesheetItem.exceptions.count > 0) { exceptionOptions.hasExceptions = true; }
          config.data.timesheetItems.push(timesheetItem);
          if (punch.punched_out_at == null) { exceptionOptions.hasOpenPunches = true; }
        });

        // Create TimesheetItems for all un-punched Shifts for the date
        var punchlessShifts = _.chain(payPeriodData.shifts)
          .where({ user_id: userId, start_date: formattedDate })
          .filter(shift => {
            return punchedShiftIds.indexOf(shift.id) == -1;
          }).value();
        $.each(punchlessShifts, (i, shift) => {
          var timesheetItem = new TimesheetItem(newTimesheetItemId(), userId, null, shift, config.exceptionRules, timsheetIsEditable, config.jobSitesEnabled, config.showBreakPunches, config.paidBreaks, config.photoPunchesEnabled, config.trcEnabled, config.managerApproval, config.shiftBasedPunches, config.payCodeEnabled, config.isReadOnlyTimesheets, config.isEnterpriseCompany);
          if (timesheetItem.exceptions.count > 0) { exceptionOptions.hasExceptions = true; }
          config.data.timesheetItems.push(timesheetItem);
        });
      }

      triggerOvertimeCalculation(userId);

      flagUserException(userId, exceptionOptions);
    });
  };

  const triggerOvertimeCalculation = userId => {
    const startDate = moment(config.data.payPeriodStartDate).format('YYYY-MM-DD');
    const endDate = moment(config.data.payPeriodEndDate).format('YYYY-MM-DD');

    const punchIds = config.data.timesheetItems
      .filter(item => item.punch !== null)
      .map(item => item.punch.id);

    if (punchIds.length === 0) {
      stopSpinner(config.spinner);
      return;
    }

    const reportOptions = {
      user_id: userId,
      department: config.data.department.id,
      start_date: startDate,
      end_date: endDate,
      punches: punchIds
    };

    $.ajax({
      url: '/analytics/overtime_hours',
      data: reportOptions,
      error: (xhr, status, error) => {
        const errorMessage = xhr?.responseJSON?.error_message || 'Unable to load Overtime Hours';
        setErrorFlash(errorMessage);
      }
    });
  };

  /*
   * Flags employee column if they have open Punches
   *
   * @param {Integer} userId
   * @param {Boolean} hasOpenPunches
   */
  var flagUserException = function(userId, exceptionOptions) {
    var hasOpenPunches = undefined;
    var hasExceptions = undefined;
    if (exceptionOptions != undefined) {
      hasOpenPunches = exceptionOptions.hasOpenPunches;
      hasExceptions = exceptionOptions.hasExceptions;
    }

    if (hasExceptions === undefined) {
      hasExceptions =  _.some(config.data.selectedUserTimesheetItems, timesheetItem => {
        return timesheetItem.exceptions.count > 0;
      });
    }
    if (hasOpenPunches === undefined) {
      hasOpenPunches = _.some(config.data.selectedUserTimesheetItems, timesheetItem => {
        return timesheetItem.punch && timesheetItem.punch.punched_out_at == null;
      });
    }

    config.selectors.userRow(userId).children('i,div.clr').remove();
    if (hasExceptions) {
      config.selectors.userRow(userId).append('<i class="fa fa-warning"></i><div class="clr"></div>');
    }

    if (hasOpenPunches) {
      config.selectors.userRow(userId).addClass('missing-values');
    } else {
      config.selectors.userRow(userId).removeClass('missing-values');
    }
  };

  /*
    * Filters and sorts timesheet items for a given user.
    *
    * @param {Array} timesheetItems - The array of timesheet items.
    * @param {Integer} userId - The ID of the user.
    * @returns {Array} - The filtered and sorted array of timesheet items.
    */
  function filterAndSortTimesheetItems(timesheetItems, userId) {
    return _.chain(timesheetItems)
      .where({ userId: userId })
      .filter(timesheetItem => {
        // If no exception types filter, return all items.
        if (!config.filter.exceptionTypes || config.filter.exceptionTypes.length === 0 ) {
          return true;
        }

        // If all_exceptions is selected, return items with at least one exception.
        if (config.filter.exceptionTypes.includes('all_exceptions')) {
          return timesheetItem.exceptions.count > 0;
        }

        // Return items that have any of the exception types listed in the config array.
        return timesheetItem.exceptions.types.some(type =>
          config.filter.exceptionTypes.includes(type)
        );
      })
      .filter(timesheetItem => {
        // If overtimeHoursOnly filter is selected, return items with overtime hours
        if (config.filter.overtimeHoursOnly) {
          return timesheetItem.hasOvertime;
        } else {
          return true;
        }
      })
      .sortBy(timesheetItem => {
        return timesheetItem.startsAt();
      })
      .value();
  }

  /*
   * Render view for User
   *
   * @param {Integer} userId
   */
  var renderUser = function(userId) {
    loadTimesheet(location.href, userId).then(() => {
      renderUserRows(userId);
    });
  };

  /*
  * Render timesheet item rows for User
  *
  * @param {Integer} userId
  */
  var renderUserRows = function(userId) {
    // Select row
    config.selectors.userRows().removeClass('active');
    config.selectors.userRow(userId).addClass('active');
    // Clear prior view
    $('[data-toggle=tooltip]').tooltip('destroy');
    config.selectors.dataRows().remove();

    // Set User and their TimesheetItems as current
    config.data.selectedUserId = userId;
    config.data.selectedUserTimesheetItems = filterAndSortTimesheetItems(config.data.timesheetItems, userId);

    // Render User's timesheetItems
    $.each(config.data.selectedUserTimesheetItems, (i, timesheetItem) => {
      config.selectors.placeholderRow.before(timesheetItem.render());
    });

    // Render User's totals
    renderUserTotals();

    // Bind helpers
    $('[data-toggle=tooltip]').tooltip();
  };

  /*
   * Render totals for currently selected User
   *
   */
  var renderUserTotals = function() {
    var renderedUserName;
    var totalPaidDuration = _.chain(config.data.selectedUserTimesheetItems)
      .reduce((memo, timesheetItem) => { return memo + timesheetItem.paidDuration(); }, 0)
      .value();

    var $userRow        = config.selectors.userRow(config.data.selectedUserId);
    var totalUserHours  = msUtil.durationToHours(totalPaidDuration, true);

    config.selectors.userTitle.removeClass('hidden');
    renderedUserName = $userRow.text();
    config.selectors.userTimesheetButtonName.text(renderedUserName);

    if ($userRow.attr('data-employee-id').length) {
      renderedUserName = renderedUserName + '(' + $userRow.attr('data-employee-id') + ')';
    }
    config.selectors.userName.text(renderedUserName);
    config.selectors.totalUserHours.text(totalUserHours);
  };

  /**
   * Load Punch History modal for audits
   *
   * @param {Number} id  Required. Punch ID.
   */
  var renderHistoryModal = function(id) {
    var spinner = showLoading();
    return $.get(config.routes.auditsPunchPath(id))
      .then(html => {
        spinner.stop();
        config.selectors.punchHistoryModal.html(html).modal('show');
      });
  };

  /*
   * Load and initialize Punch modal for new/edit, with dates bounded to Shift if present, else pay period start/end+1day
   * @note $(this) is not supported for multiple datepicker inits, hence verbose references
   *
   * @param {String} url                New/edit route
   * @param {Object} params             Optional. Querystring params
   * @option params {Integer} user_id   Required. User ID to create new Punch for
   * @option params {Integer} shift_id  Optional. Shift to associate new Punch with.
   *
   */
  var renderModal = function(url, params) {
    var spinner = showLoading();
    return $.get(url, params)
      .then(html => {
        spinner.stop();
        config.selectors.punchModal.html(html).modal('show');
        initPunchModalDatePickers(html);
      });
  };

  var initPunchModalDatePickers = function() {
    $('#punch-modal .datepicker').each(function() {
      var $self = $(this);
      var addDays = $self.attr('id') == 'punch_punched_in_date' ? 0 : 1;

      var punchDate = $self.attr('data-punchdate');
      var startDate = '';

      if (punchDate == config.data.payPeriodStartDate) {
        startDate = moment(config.data.payPeriodStartDate).subtract(1, 'days').format(config.dateFmt);
      } else {
        startDate = config.data.payPeriodStartDate;
      }


      $self.datepicker({
        autoclose: true,
        format: 'yyyy-mm-dd',
        orientation: 'auto',
        startDate: startDate,
        endDate: $self.attr('data-date') ? moment($self.attr('data-date')).add(addDays, 'days').format(config.dateFmt) : moment(config.data.payPeriodEndDate).add(addDays, 'days').format(config.dateFmt),
        language: I18n.locale
      });
    });
    var timeFormat;
    timeFormat = (user_prefers_12hr()) ?  'g:ia' : 'H:i';
    $('#punch-modal .time').timepicker({
      timeFormat: timeFormat,
      maxTime: '23:59',
      step: 15,
    });
  };

  var showLoading = function() {
    return msUtil.spinner(config.selectors.departmentTimesheetBox[0].id, 150);
  };

  // Punches are editable only if the Timesheet is open
  var isEditable = function() {
    return config.data.timesheetId === null;
  };

  // Timesheet can only be closed if all Punches have been closed
  var isCloseable = function() {
    return !_.some(config.data.timesheetItems, timesheetItem => {
      return timesheetItem.punch && timesheetItem.punch.punched_out_at === null;
    });
  };

  /*
   * Finds selected TimesheetItem object based on its ID
   *
   * @param {Integer} id
   * @return {Object} timesheetItem
   */
  var findTimesheetItem = function(id) {
    return _.findWhere(config.data.selectedUserTimesheetItems, { id: id });
  };

  /*
   * Create new Shiftless timesheetItem object based on successful Punch creation
   *
   * @param {Object} punch
   */
  var createTimesheetItem = function(punch) {
    var newTimesheetItem = new TimesheetItem(newTimesheetItemId(), config.data.selectedUserId, punch, null, config.exceptionRules, isEditable(), config.jobSitesEnabled, config.showBreakPunches, config.paidBreaks, config.photoPunchesEnabled, config.trcEnabled, config.managerApproval, config.shiftBasedPunches, config.payCodeEnabled,config.isReadOnlyTimesheets, config.isEntepriseCompany);
    config.data.timesheetItems.push(newTimesheetItem);
    renderUser(config.data.selectedUserId);
    timesheetEvents.trigger('timesheetItem:update', [newTimesheetItem]);
  };

  /*
   * Generates unique timesheetItem ID based on global sequence
   *
   * @return {String} unique ID
   */
  var newTimesheetItemId = function() {
    return 'timesheet-item-' + ++config.data.timesheetItemsIdSequence;
  };

  /*
   * Checks if "date" is between "start" and "end"
   *
   * @param {Date} date
   * @param {String} _start , ex. "2017-04-01"
   * @param {String} _end , ex. "2017-04-30"
   *
   * @return {Boolean} true/false
  */
  var dateBetween = function(date, _start, _end) {
    var start = Date.parse(_start).setHours(0,0,0,0);
    var end = Date.parse(_end).setHours(23,59,59,999);
    if((date >= start) && (date <= end)){
      return true;
    } else {
      return false;
    }
  };


  var getFilters = function(type, $el){

    var raw_vals = $el.val();
    var valid_items = [];
    var req_ids = [];

    valid_items = _.filter(raw_vals, val =>{
      return val.includes(type+':');
    });

    req_ids = _.map(valid_items, item =>{
      return item.split(':')[1];
    });

    return req_ids;
  };

  var userHas = function(userRow, property, rawFilter){
    // Make sure we got an array
    var filterData = _.flatten([rawFilter]);

    // No filter applied, return early
    if(filterData.length == 0){ return true; }

    // Data attr ex) data-skill=["1","2","3"]
    var userData = _.flatten([$(userRow).data(property)]);

    // Filter that the userData contains all the filter requirements
    var meetsRequirement = _.all(filterData, f =>{ return _.contains(userData, parseInt(f)); });

    return meetsRequirement;
  };

  var disableReopenButton = function(state){
    // Handle reload of timesheet setting the correct state.
    if (config.data.department.timesheet_processing) {
      config.selectors.closeTimesheetButton.text(I18n.t('timesheets.index.processing'));
      config.selectors.closeTimesheetButton.addClass('btn-red');
      config.selectors.closeTimesheetButton.prop('disabled', true);
      config.selectors.reopenTimesheetButton.text(I18n.t('timesheets.index.processing'));
      config.selectors.reopenTimesheetButton.prop('disabled', true);
    } else {
      config.selectors.closeTimesheetButton.text(I18n.t('timesheets.index.approve_timesheet'));
      config.selectors.closeTimesheetButton.prop('disabled', false);
      config.selectors.closeTimesheetButton.removeClass('btn-red');
      config.selectors.reopenTimesheetButton.text(I18n.t('timesheets.index.reopen_timesheet'));
      config.selectors.reopenTimesheetButton.prop('disabled', false);
    }

    // Handle initial reopen button disable before background timesheet request is sent.
    if (state == true) {
      config.selectors.reopenTimesheetButton.text(I18n.t('timesheets.index.processing'));
      config.selectors.reopenTimesheetButton.prop('disabled', true);
    } else {
      config.selectors.reopenTimesheetButton.text(I18n.t('timesheets.index.reopen_timesheet'));
    }

    return true;
  };

  return {
    init: init,
    timesheetEvents: timesheetEvents
  };

})(jQuery, window, document);

window.timesheets = timesheets;
